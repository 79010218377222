<template>
  <ez-content class="contacts-drop">
    <ez-container>
      <ez-box class="box--drop">
        <template slot="body">
          <div class="drop-area-container">
            <ez-drop-area
              visible
              icon="download"
              :text="$t('import-new.drop-area-text')"
              @click="onDropAreaClick"
              @drop="onDropAreaClick"
              v-if="!selectedFile"
            />
            <div class="drop-success" v-else>
              <div class="drop-success__title">
                {{ $t('notifications.new-import-selected-file-pre-label') }}
              </div>
              <div class="drop-success__name">{{ fileName }}</div>
            </div>
          </div>
          <div class="title">
            <ez-link
              href="/import/Contacts_Import_Sample.xlsx"
            >{{ $t('import-new.download-sample-link-label')}}</ez-link>
          </div>
          <div class="header">
            <ez-checkbox
              v-model="isSkipFirstRow"
              :label="$t('import-new.skip-header-checkbox-label')"
            />
          </div>
          <div class="buttons">
            <ez-button-group>
              <ez-button type="secondary" @click="onCancel">{{ $t('cancel-button') }}</ez-button>
              <ez-button
                type="primary"
                @click="onCreateImport"
                :disabled="uploading || selectedFile == null"
                :preloader="uploading"
              >{{ $t('import-new.upload-file-button') }}</ez-button>
            </ez-button-group>
          </div>
        </template>
      </ez-box>
    </ez-container>
  </ez-content>
</template>

<script>
import get from 'lodash/get';
import * as navActions from '../../store/nav/actions';
import * as injectNames from '../../services/names';

export default {
  name: 'AppNewImport',
  dependencies: ['$timeout', injectNames.CONTACTS_API, '$log'],
  data() {
    return {
      isSkipFirstRow: true,
      uploading: false,
      selectedFile: null,
    };
  },
  created() {
    this.setBreadCrumbs();
  },
  computed: {
    enabled() {
      return this.selectedFile != null;
    },
    fileName() {
      return this.selectedFile != null ? this.selectedFile.name : null;
    },
  },
  methods: {
    setBreadCrumbs() {
      this.$store.dispatch(`nav/${navActions.SET_BREADCRUMBS}`, [
        {
          name: this.$t('app-name'),
          status: '',
          href: '/',
        },
        {
          name: this.$t('import-new.title'),
        },
      ]);
      window.document.title = `${this.$t('app-public-title')} - ${this.$t(
        'import-new.title',
      )}`;
    },
    onCancel() {
      this.$router.push({ name: 'dashboard' });
    },
    async onCreateImport() {
      this.uploading = true;
      const api = this[injectNames.CONTACTS_API];
      try {
        const result = await api.createImport(
          this.selectedFile,
          this.isSkipFirstRow,
        );

        this.navigateToImport(result);
      } catch (err) {
        this.$log({ err });
      }
      this.uploading = false;
    },
    navigateToImport(item) {
      const importId = get(item, 'attributes.id', 'unknown-id');
      this.$router.push({ name: 'import-mapping', params: { importId, item } });
    },

    // handle file selection
    onDropAreaClick([selectedFile = null]) {
      // TODO: check file type (xlsx or csv)
      this.$log({ selectedFile });
      this.selectedFile = selectedFile;
    },
  },
};
</script>
